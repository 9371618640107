import { takeLatest, put, cancel, take, delay, all } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';
import { track, setUserId } from '@amplitude/analytics-browser';

import Alert from 'components/Alert';

import * as api from 'services/api';
import network from 'lib/network';
import { gtm } from 'utils';

import { call } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';
import * as navigationStore from 'store/nodes/navigation';
import * as onboardingStore from 'widgets/Onboarding/store';
import * as subscriptionStore from 'widgets/Subscription/store';

import jwtToken from 'jwt-decode';
import SocialAuthToast from 'components/SocialAuthToast';
import * as actions from '../actions';

export const config = {
  action: actions.doSignIn.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.doSignIn>) {
  const { email, password, event } = action.payload;

  const { errors, data } = yield* call(() =>
    network
      .request<{
        access: string;
        refresh: string;
      }>('/stack-1/auth/login', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .body({ email, password })
      .post(),
  );

  const error = Array.isArray(errors) ? errors[0] : errors;
  if (error?.message === 'Please use OAuth to sign in to your account.') {
    yield put(actions.doSignUpDone());
    yield call(() =>
      Alert.info(<SocialAuthToast />, {
        autoClose: 10000,
      }),
    );
    yield cancel();
    return;
  }

  if (error?.message === 'You are not registered') {
    yield put(actions.doSignInDone());
    yield put(actions.doSignUp(email, password));
    yield cancel();
    return;
  }

  if (error || !data) {
    Alert.error(error?.message || 'Server error #5');
    yield* call(() => api.credentials.clear());
    yield put(actions.doSignInDone(error || 'error_login'));
    yield cancel();
    return;
  }

  const { access, refresh } = data;
  if (!access || !refresh) {
    yield put(actions.doSignInDone('error_login'));
    yield cancel();
    return;
  }

  yield* call(() => api.credentials.set(access, refresh));

  yield put(userStore.actions.loadMe());
  yield put(onboardingStore.actions.loadState());
  yield all([take(userStore.actions.loadMeDone.type), take(onboardingStore.actions.loadStateDone.type)]);
  yield delay(10);

  yield put(navigationStore.actions.route());
  yield take(navigationStore.actions.routeDone.type);

  yield put(actions.doSignInDone());
  yield put(actions.loginSuccess());

  const { payload: statePlan } = yield take(subscriptionStore.actions.setState.type);
  const decodedToken = yield* call(
    () =>
      jwtToken(access) as {
        session_id: number;
        login_type: 'login' | 'register';
      },
  );

  yield call(() =>
    gtm.dataLayer.push({
      event: event || decodedToken?.login_type,
      method: 'email',
      userid: decodedToken?.session_id.toString(),
    }),
  );

  yield call(() =>
    gtm.dataLayer.push({
      event: 'userID',
      userid: decodedToken?.session_id.toString(),
      current_plan: statePlan.state.plan === '0' ? 'free' : 'pro',
    }),
  );
  yield* call(() => setUserId(decodedToken?.session_id.toString()));
  yield* call(() => track('Login'));
}
