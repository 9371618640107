import { memo, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/joy';

import { navigate } from 'navigation/methods';

export type SummaryPreparedProps = {
  resourceId?: number;
  type?: string;
  title?: string | null;
  summary?: string | null;
};

const SummaryPrepared = (props: SummaryPreparedProps) => {
  const { summary, title, resourceId, type } = props;

  if (!summary) {
    return null;
  }

  const handleNavigateToMaterial = useCallback(() => {
    if (!resourceId || !type) {
      return;
    }
    navigate('Content', { resourceId, resourceType: type });
  }, [resourceId, type]);

  if (!resourceId) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" m={1} gap={2}>
      <Typography fontSize={14}>Summary prepared</Typography>
      <Typography
        fontSize={16}
        fontWeight={500}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {title}
      </Typography>
      <Typography
        fontSize={14}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {summary}
      </Typography>
      <Button variant="outlined" color="neutral" onClick={handleNavigateToMaterial}>
        Open material
      </Button>
    </Box>
  );
};

export default memo(SummaryPrepared);
