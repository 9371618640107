import { useMemo } from 'react';
import type { ResourceType } from 'app/entities';
import { guard } from 'utils';

type ResourceInfo =
  | { id: string; type: 'Folder' }
  | { id: number; type: 'Material'; legacy: { id: number; type: 'content' } }
  | { id: number; type: 'Note' }
  | { id: number; type: 'Document' }
  | { id: number; type: 'LinkToContent' }
  | null;

const useResourceInfo = (data: ResourceType | null): ResourceInfo =>
  useMemo(() => {
    const id = data && 'resourceId' in data ? data.resourceId : data?.id;

    if (guard.isSpaceFolder(data)) {
      return {
        id: String(id),
        type: 'Folder',
      } as const;
    }

    if (guard.isMaterial(data)) {
      return {
        id: Number(id),
        type: 'Material',
        legacy: {
          id: Number(id),
          type: 'content',
        },
      } as const;
    }

    if (guard.isNote(data)) {
      return {
        id: Number(id),
        type: 'Note',
      } as const;
    }

    if (guard.isDocument(data)) {
      return {
        id: Number(id),
        type: 'Document',
      } as const;
    }

    if (guard.isLinkToContent(data)) {
      return {
        id: Number(id),
        type: 'LinkToContent',
      } as const;
    }

    if (guard.isRagMaterial(data)) {
      return null;
    }

    return null;
  }, [data]);

export default useResourceInfo;
