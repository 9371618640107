import type { SpaceEntityType, SpaceFolderType, SpaceContentType } from 'app/entities';

export interface SourceEntityType {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  parentId: number | null;
  materializedPath: string;
  teamSpaceId: number;
  userId: number;
  type: 'FOLDER' | 'CONTENT' | 'NOTE' | 'DOCUMENT';
  position: number;
  resourceId: number | null;
  contentId: number | null;
  noteId: number | null;
  documentId: number | null;
  isPrivate: boolean;
  cover: string | null;
  user: any | null;
  contentImages: any[];
  childrenCount?: number | null;
  hasFolder?: boolean;
}

function prepareParent(libraryId: string | string[], parentId: number | null) {
  if (!parentId) {
    return parentId;
  }
  const parentIdStr = String(parentId);
  if (typeof libraryId === 'string') {
    return parentIdStr === libraryId ? null : parentId;
  }
  return libraryId.includes(parentIdStr) ? null : parentId;
}

function convertEntity(libraryId: string | string[], source: SourceEntityType): SpaceEntityType {
  if (source.type === 'FOLDER') {
    return {
      id: String(source.id),
      userId: source.userId,
      spaceId: source.teamSpaceId,
      parentId: String(prepareParent(libraryId, source.parentId) ?? 'root'),
      position: source.position,
      title: source.title,
      description: source.description,
      cover: source.cover,
      contentImages: source.contentImages,
      childrenCount: source.childrenCount || 0,
      isPrivate: source.isPrivate,
      isExpanded: false,
      createdAt: source.createdAt,
      updatedAt: source.updatedAt,
      __typename: 'Folder',
    } as SpaceFolderType;
  }

  let resourceType: 'Material' | 'Document' = 'Material';
  if (source.type === 'DOCUMENT') {
    resourceType = 'Document';
  }

  return {
    id: String(source.id),
    userId: source.userId,
    spaceId: source.teamSpaceId,
    parentId: String(prepareParent(libraryId, source.parentId) ?? 'root'),
    position: source.position,
    resourceType,
    resourceId: source.resourceId ?? 0,
    isPrivate: source.isPrivate,
    createdAt: source.createdAt,
    updatedAt: source.updatedAt,
    __typename: 'Content',
  } as SpaceContentType;
}

function sourceEntityToSpaceEntity<T extends SourceEntityType | SourceEntityType[]>(
  libraryId: string | string[],
  data: T,
): T extends SourceEntityType[] ? SpaceEntityType[] : SpaceEntityType {
  if (Array.isArray(data)) {
    return data.map((item) => convertEntity(libraryId, item)) as T extends SourceEntityType[] ? SpaceEntityType[] : SpaceEntityType;
  }
  return convertEntity(libraryId, data) as T extends SourceEntityType[] ? SpaceEntityType[] : SpaceEntityType;
}

export default sourceEntityToSpaceEntity;
