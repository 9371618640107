import { useMemo } from 'react';
import { useColorScheme } from '@mui/joy';
import { type UseTreeItem2Status } from '@mui/x-tree-view-pro';

import useCss from 'hooks/useCss';
import cn from 'classnames';

interface UseItemContentBackgroundParams {
  dragOverType: 'files' | 'nodes' | false;
  status: UseTreeItem2Status;
}

export interface UseItemThemeParams extends UseItemContentBackgroundParams {
  nodeType: string;
}

const useItemContentBackground = ({ dragOverType, status }: UseItemContentBackgroundParams) => {
  const { colorScheme } = useColorScheme();

  const selectedColorValue = useMemo(
    () =>
      colorScheme === 'light'
        ? `color-mix(in srgb, var(--joy-palette-neutral-100) 75%, var(--joy-palette-neutral-200) 25%)`
        : `color-mix(in srgb, var(--joy-palette-neutral-700) 75%, var(--joy-palette-neutral-600) 25%)`,
    [colorScheme],
  );

  const focusedColorValue = useMemo(() => `var(--joy-palette-neutral-${colorScheme === 'light' ? '50' : '800'})`, [colorScheme]);

  const primaryColorValue = useMemo(
    () =>
      colorScheme === 'light'
        ? `color-mix(in srgb, var(--joy-palette-primary-500) 20%, var(--joy-palette-neutral-50) 80%)`
        : `color-mix(in srgb, var(--joy-palette-primary-500) 20%, var(--joy-palette-neutral-800) 80%)`,
    [colorScheme],
  );

  const regularColor = useMemo(() => {
    if (dragOverType === 'files') {
      return primaryColorValue;
    }
    if (dragOverType === 'nodes') {
      return primaryColorValue;
    }
    if (status.selected) {
      return selectedColorValue;
    }
    if (status.focused) {
      return focusedColorValue;
    }
    return undefined;
  }, [dragOverType, status.focused, status.selected, status.editing, focusedColorValue, selectedColorValue, primaryColorValue]);

  const hoverColor = useMemo(
    () =>
      colorScheme === 'light'
        ? `color-mix(in srgb, var(--joy-palette-neutral-50) 50%, var(--joy-palette-neutral-100) 50%)`
        : `color-mix(in srgb, var(--joy-palette-neutral-800) 50%, var(--joy-palette-neutral-700) 50%)`,
    [colorScheme],
  );

  return useMemo(
    () => ({
      regularColor,
      hoverColor,
    }),
    [regularColor, hoverColor],
  );
};

/**
 * Хук для получения темизированных классов элемента.
 *
 * Он вычисляет:
 * - rootClass – на основе поля type
 * - dragAndDropOverlayClass – статичный стиль оверлея для DnD
 * - contentClass – стиль контейнера с фоновым цветом, зависящим от состояния перетаскивания и редактирования
 * - iconContainerClass – стиль для контейнера иконки, зависящий от colorScheme
 *
 * Все стили создаются с помощью useCss, а затем преобразуются в строку с помощью cx.
 */
const useItemTheme = ({ nodeType, dragOverType, status }: UseItemThemeParams) => {
  const { colorScheme } = useColorScheme();

  const { regularColor, hoverColor } = useItemContentBackground({ dragOverType, status });

  const rootClass = useCss({
    pointerEvents: status.editing ? 'none' : 'unset',
  });

  const finalRootClass = useMemo(() => {
    const levelClass = nodeType === 'space' ? 'root-level' : undefined;
    return cn(levelClass, rootClass);
  }, [nodeType, rootClass]);

  const dragAndDropOverlayClass = useCss({
    borderRadius: 0,
    backgroundColor: 'transparent',
  });

  const contentClass = useCss({
    '--item-hover-value': 0,
    '--item-background-color': regularColor,
    backgroundColor: 'var(--item-background-color)',
    transition: 'none',
    gap: 2,
    borderRadius: 18,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingRight: '0.25rem',
    '&:hover': {
      '--item-hover-value': 1,
      '--item-background-color': hoverColor,
      backgroundColor: 'var(--item-background-color)',
    },
    '&.Mui-selected:hover': {
      '--item-background-color': regularColor,
    },
  });

  const iconContainerClass = useCss({
    color: `var(--joy-palette-neutral-${colorScheme === 'light' ? '500' : '300'})`,
    opacity: 0.5,
  });

  return { rootClass: finalRootClass, dragAndDropOverlayClass, contentClass, iconContainerClass };
};

export default useItemTheme;
