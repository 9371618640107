import { type SagaReturnType, takeEvery, cancel, put } from 'redux-saga/effects';

import { call, select } from 'store/utils/saga/effects';
import * as userStore from 'store/nodes/user';

import Alert from 'components/Alert';
import network from 'lib/network';

import { actions } from '../slices/permission';
import sourceUserPermissionToSpaceUserPermission, { type SourceUserPermissionType } from '../../model/sourceUserPermissionToSpaceUserPermission';

export const config = {
  action: actions.createByEmail.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.createByEmail>) {
  const { spaceId, userEmail, permissionId } = action.payload;

  const user = yield* select(userStore.selectors.getMy);
  if (!spaceId || !userEmail || !permissionId || !user) {
    yield put(actions.createByEmailDone({ spaceId, userEmail, permissionId }));
    yield cancel();
    return;
  }

  const inviteResponse = yield* call(() =>
    network
      .request<SourceUserPermissionType[]>(`/stack-2/user/team`)
      .body({
        team_space_id: spaceId,
        email: userEmail,
        added_by_user_id: user.id,
      })
      .post(),
  );

  const teamResponse = yield* call(() =>
    network
      .request<any>('/stack-2/user/team', {
        baseUrl: process.env.PROTECTED_API_URL || '/api',
      })
      .get(),
  );

  // console.log('GGG', inviteResponse, teamResponse);
  // const { data, hasError } = yield* call(() =>
  //   network
  //     .request<SourceUserPermissionType[]>(`/stack-2/user/team-space/${spaceId}/createPermission`)
  //     .body({ user_id: userId, permission_id: permissionId })
  //     .post(),
  // );
  //
  // if (hasError || !data) {
  //   Alert.error('Error creating permission');
  //   yield put(actions.createByEmailDone({ spaceId, userId, permissionId }));
  //   yield cancel();
  //   return;
  // }
  //
  // yield put(actions.setUserPermissions({ items: sourceUserPermissionToSpaceUserPermission(data), mode: 'reset' }));
  // yield put(actions.createByEmailDone({ spaceId, userId, permissionId }));
}
