import { createContext, useContext, type PropsWithChildren, type FC, type ReactNode, memo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, type BoxProps } from '@mui/joy';

import * as copilotStore from 'widgets/Copilot/store';

const UtilityBarContext = createContext<boolean>(false);

export const useUtilityBarContext = () => useContext(UtilityBarContext);

export type UtilityBarProps = {
  left?: ReactNode;
  right?: ReactNode;
  isShowForce?: boolean;
} & Pick<BoxProps, 'p' | 'px' | 'py' | 'pt' | 'pr' | 'pb' | 'pl'>;

const Item: FC<PropsWithChildren<{ canHidden?: boolean; hidden?: boolean }>> = memo(
  (props) => {
    const { canHidden, children, hidden } = props;
    const isShow = useUtilityBarContext();
    if (hidden) {
      return null;
    }
    if (!isShow && canHidden) {
      return null;
    }
    return <>{children}</>;
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

const UtilityBar: FC<UtilityBarProps> = (props) => {
  const { left, right, isShowForce, ...rest } = props;
  const rightBarWidth = useSelector(copilotStore.selectors.rightBarWidth);
  const isShow = useSelector((state: DefaultRootState) => isShowForce ?? copilotStore.selectors.rightBarIsShow(state));

  return (
    <UtilityBarContext.Provider value={isShow}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        boxSizing="border-box"
        minWidth={isShow ? rightBarWidth : undefined}
        {...rest}
      >
        {left}
        <Box flex={1} />
        {right}
      </Box>
    </UtilityBarContext.Provider>
  );
};

const UtilityBarMemoized = memo(UtilityBar, (prevProps, nextProps) => isEqual(prevProps, nextProps)) as unknown as typeof UtilityBar & {
  Item: typeof Item;
};

UtilityBarMemoized.Item = Item;

export default UtilityBarMemoized;
