import { memo } from 'react';

import { Box, Typography } from '@mui/joy';
import { useResponsive } from 'hooks';

import TagsFilter from './TagsFilter';

const TopBarDesktop = () => {
  const responsive = useResponsive();

  if (!responsive.is.tabletL) {
    return null;
  }

  return (
    <Box mt={5} mb={3}>
      <Box display="flex" flexDirection="row" alignItems="center" ml={-0.5}>
        <Typography fontSize={18} fontWeight={500} ml={0.5}>
          Topics
        </Typography>
      </Box>
      <Box mt={0.5}>
        <TagsFilter textSize={13} />
      </Box>
    </Box>
  );
};

export default memo(TopBarDesktop);
