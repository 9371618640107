import { type ReactElement, memo, useCallback, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/joy';

import { usePerson } from 'hooks';

import Icon from 'ui/Icon';

import ContentSwiper from './ui/ContentSwiper';

const defaultProps = {
  editable: false,
};

type PlaylistsProps = {
  userLogin?: string;
  authorId?: number;
  editable?: boolean;
} & typeof defaultProps;

const Contents = (props: PlaylistsProps): ReactElement | null => {
  const { userLogin, authorId, editable } = props;

  const [isEditable, setEditable] = useState(false);

  const { person } = usePerson(authorId ? { type: 'Author' as const, id: authorId } : { type: 'User' as const, login: userLogin || 'my' });

  const handleEditStart = useCallback(() => {
    setEditable(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setEditable(false);
  }, []);

  if (!person || !Array.isArray(person.contentIds) || !person.contentIds.length) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography fontSize={20} fontWeight={500} flex={1} mb={2}>
          Added
        </Typography>
        {userLogin === 'my' && editable && (
          <Tooltip arrow variant="outlined" size="sm" title="Edit material list">
            <IconButton onClick={!isEditable ? handleEditStart : handleEditCancel}>
              <Icon size="xl" name="gear" fw weight="light" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <ContentSwiper isEditable={isEditable} contentIds={person.contentIds} />
    </Box>
  );
};

Contents.defaultProps = defaultProps;

export default memo(Contents);
