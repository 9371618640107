import { type HTMLAttributes, type SyntheticEvent, type MouseEvent, forwardRef, memo, useEffect, useCallback, useMemo } from 'react';
import {
  type TreeItem2Props,
  type UseTreeItem2LabelInputSlotOwnProps,
  type UseTreeItem2LabelSlotOwnProps,
  TreeItem2,
  useTreeItem2Utils,
} from '@mui/x-tree-view-pro';
import { isEqual } from 'lodash';

import useDragState, { type UseDragStateOptions } from 'hooks/useDragState';
import useHoverState, { type UseHoverStateOptions } from 'hooks/useHoverState';

import { Label, type LabelProps, LabelInput, type LabelInputProps } from './Label';

import useFolderMeta from '../../../model/useFolderMeta';
import useLibraryIds from '../../../model/useLibraryIds';
import { useTreeViewInteractions } from '../model/treeViewItemInteractionUtils';
import useItemTheme from '../model/useItemTheme';

export interface ItemProps extends TreeItem2Props {
  hasAdd?: (itemId: string) => boolean;
  hasMenu?: (itemId: string) => boolean;
  onAddClick?: (event: MouseEvent<HTMLButtonElement>, itemId: string) => void;
  onMenuClick?: (event: MouseEvent<HTMLButtonElement>, itemId: string) => void;
  onDragStart?: TreeItem2Props['onDragStart'];
  onItemLabelChangingStart?: (itemId: string) => void;
  onItemLabelChangingSave?: (itemId: string, newLabel: string) => void;
  onItemLabelChangingCancel?: (itemId: string) => void;
}

const Item = forwardRef<HTMLLIElement, ItemProps>((props, ref) => {
  const { children, itemId, onItemLabelChangingStart, onItemLabelChangingSave, onItemLabelChangingCancel, onAddClick, onMenuClick } = props;
  const libraryIds = useLibraryIds();

  const { dragOverType, handleDragEnter, handleDragOver, handleDragLeave, handleDrop } = useDragState();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHoverState({
    shouldSetHovered: useCallback<NonNullable<UseHoverStateOptions['shouldSetHovered']>>(
      (event) => {
        const targetItemId = (event.target as HTMLDivElement)?.closest('[role="treeitem"]')?.getAttribute('itemId');
        if (!targetItemId) {
          return true;
        }
        return !libraryIds?.includes(targetItemId.replace(/.*?::/, ''));
      },
      [libraryIds],
    ),
  });

  const { setTreeItemInteractions } = useTreeViewInteractions();
  const { interactions, status } = useTreeItem2Utils({
    itemId,
    children,
  });

  useEffect(() => {
    setTreeItemInteractions(itemId, interactions);
    return () => {
      setTreeItemInteractions(itemId, undefined);
    };
  }, [itemId, interactions, setTreeItemInteractions]);

  const { icon, isPrivate, childrenCount, type } = useFolderMeta(itemId);

  const handleContentDoubleClick = useCallback<NonNullable<UseTreeItem2LabelSlotOwnProps['onDoubleClick']>>((event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleInputBlur = useCallback<NonNullable<UseTreeItem2LabelInputSlotOwnProps['onBlur']>>((event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleInputKeyDown = useCallback<NonNullable<UseTreeItem2LabelInputSlotOwnProps['onKeyDown']>>((event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleAddClick = useCallback<NonNullable<LabelProps['onAddClick']>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onAddClick?.(event, itemId);
    },
    [onAddClick, itemId],
  );

  const handleMenuClick = useCallback<NonNullable<LabelProps['onMenuClick']>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onMenuClick?.(event, itemId);
    },
    [onMenuClick, itemId],
  );

  useEffect(() => {
    if (status.editing) {
      onItemLabelChangingStart?.(itemId);
    }
  }, [status.editing, itemId]);

  const handleSaveItemLabel = useCallback(
    (event: SyntheticEvent, label: string) => {
      interactions.handleSaveItemLabel(event, label);
      onItemLabelChangingSave?.(itemId, label);
    },
    [interactions.handleSaveItemLabel, itemId],
  );

  const handleCancelItemLabelEditing = useCallback(
    (event: SyntheticEvent) => {
      interactions.handleCancelItemLabelEditing(event);
      onItemLabelChangingCancel?.(itemId);
    },
    [interactions.handleCancelItemLabelEditing, itemId],
  );

  const hasAdd = useMemo(() => {
    return props.hasAdd?.(itemId) ?? false;
  }, [itemId, props.hasAdd]);

  const hasMenu = useMemo(() => {
    return props.hasMenu?.(itemId) ?? false;
  }, [itemId, props.hasMenu]);

  const { rootClass, contentClass, iconContainerClass, dragAndDropOverlayClass } = useItemTheme({ nodeType: type, dragOverType, status });

  return (
    <TreeItem2
      key={`${itemId}-${status.editing ? 'editing' : 'normal'}`}
      {...props}
      ref={ref}
      slots={{
        label: Label,
        labelInput: LabelInput,
      }}
      slotProps={{
        root: {
          className: rootClass,
          itemId,
          'data-editing': status.editing,
          'data-node-type': type === 'space' ? 'space' : 'folder',
        } as HTMLAttributes<HTMLLIElement>,
        dragAndDropOverlay: {
          className: dragAndDropOverlayClass,
        },
        content: {
          className: contentClass,
          onDragEnter: handleDragEnter,
          onDragOver: handleDragOver,
          onDragLeave: handleDragLeave,
          onDrop: handleDrop,
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        },
        iconContainer: {
          className: iconContainerClass,
        },
        label: {
          icon: dragOverType === 'files' ? 'svg://cloud-arrow-up' : icon,
          childrenCount,
          isRoot: type === 'space',
          isHovered,
          isPrivate,
          editable: status.editable,
          editing: status.editing,
          toggleItemEditing: interactions.toggleItemEditing,
          sx: {},
          hasAdd,
          hasMenu,
          onDoubleClick: handleContentDoubleClick,
          onAddClick: handleAddClick,
          onMenuClick: handleMenuClick,
        } as LabelProps,
        labelInput: {
          icon,
          childrenCount,
          isRoot: type === 'space',
          isPrivate,
          onBlur: handleInputBlur,
          onKeyDown: handleInputKeyDown,
          handleSaveItemLabel,
          handleCancelItemLabelEditing,
        } as LabelInputProps,
      }}
    />
  );
});

export default memo(Item, (prevProps, nextProps) => isEqual(prevProps, nextProps));
