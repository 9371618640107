import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRoute } from 'navigation/hooks';

import { useSelector } from 'store/utils/redux/hooks';
import * as noteStore from 'store/nodes/note';

import PanelsBar from 'widgets/PanelsBar';
import { parseItemId } from 'screens/Space/model/itemIdParser';
import usePermissionsGetter from 'screens/Space/model/usePermissionsGetter';

import useIsMy from '../model/useIsMy';
import useIsEditable from '../model/useIsEditable';
import useIsLoading from '../model/useIsLoading';

import EditorProcessor, { type ContentCommandMethods } from '../components/EditorProcessor';
import CopilotConnector from '../components/CopilotConnector';
import Viewer from '../components/Viewer';
import LoadingComponent from '../components/LoadingComponent';

function usePrepareId(sourceId?: string | number) {
  const permissionsGetter = usePermissionsGetter();
  return useMemo(() => {
    if (!sourceId) {
      return {
        id: NaN,
        spaceId: undefined,
        folderId: undefined,
      };
    }
    if (typeof sourceId === 'number') {
      return {
        id: sourceId,
        spaceId: undefined,
        folderId: undefined,
      };
    }
    if (!Number.isNaN(Number(sourceId))) {
      return {
        id: Number(sourceId),
        spaceId: undefined,
        folderId: undefined,
      };
    }
    if (sourceId === 'create') {
      return {
        id: Number(sourceId),
        spaceId: undefined,
        folderId: undefined,
      };
    }
    const { spaceId, entityId: folderId } = parseItemId(sourceId.replace(/::create/, ''));
    if (!permissionsGetter.get(sourceId)?.hasAction('WRITE')) {
      return {
        id: 'create',
        spaceId: undefined,
        folderId: undefined,
      } as const;
    }
    return {
      id: 'create',
      spaceId,
      folderId,
    } as const;
  }, [sourceId, permissionsGetter]);
}

const Layout = () => {
  const route = useRoute<'Note'>();

  const editorRef = useRef<ContentCommandMethods>(null);

  const { id, spaceId, folderId } = usePrepareId(route.params?.id);
  const selectedContext = useSelector(noteStore.selectors.selectedContext);
  const isMy = useIsMy(id);
  const isEditable = useIsEditable(id);
  const isLoading = useIsLoading(id);

  const handleCopyToText = useCallback((text: string) => {
    editorRef.current?.insert(text);
  }, []);

  const contentMeta = useMemo(
    () => ({
      label: 'Note',
      value: 'note',
    }),
    [],
  );

  const contentRender = useMemo(() => {
    if (isMy && isEditable && isLoading) {
      return <LoadingComponent isLoading={isLoading} />;
    }
    if ((isMy && isEditable) || id === 'create') {
      return <EditorProcessor ref={editorRef} id={id} autofocus={id === 'create'} createParams={{ spaceId, folderId }} />;
    }
    return (
      <Viewer
        id={id}
        wrapperClassName="tiptap ProseMirror prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full"
        thresholdOffsetPx={1000}
        initialBatchSize={50}
        batchSize={50}
        loaderIndicator={<LoadingComponent isLoading />}
      />
    );
  }, [isLoading, id, EditorProcessor, Viewer, isMy, isEditable, spaceId, folderId]);

  const panelsRender = useMemo(() => {
    const map = {
      note: 'current note',
      'library-fulltext': 'library',
    } as const;
    return [
      {
        label: `AI Copilot by ${map[selectedContext]}`,
        value: 'copilot',
        component: <CopilotConnector id={id} onCopyToText={handleCopyToText} autofocus={id !== 'create'} />,
      },
    ];
  }, [isMy, id, isEditable, selectedContext, handleCopyToText]);

  if (!id) {
    return null;
  }

  return (
    <PanelsBar
      contentMeta={contentMeta}
      content={contentRender}
      panels={panelsRender}
      sx={{
        '@media (max-width: 767px)': {
          mx: -2,
        },
      }}
    />
  );
};

export default memo(Layout);
