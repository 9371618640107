import { v4 as uuidV4 } from 'uuid';
import { delay } from 'utils';
import { type SyntheticEvent, useCallback, useRef, useState } from 'react';
import { type TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering/useTreeViewItemsReordering.types';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import { parseItemId } from '../../../model/itemIdParser';
import usePermissionsGetter from '../../../model/usePermissionsGetter';
import { type SpaceTreeViewProps, type SpaceTreeViewRef } from '../../../elements/SpaceTreeView';
import * as selectors from '../../../store/selectors';

const getExpandedItemIds = () => {
  const stringifyItemIds = localStorage.getItem('space/menu/expanded');
  if (!stringifyItemIds) {
    return [];
  }
  try {
    return JSON.parse(stringifyItemIds);
  } catch {
    return [];
  }
};

const useTree = () => {
  const dispatcher = useDispatcher();
  const spaceTreeViewRef = useRef<SpaceTreeViewRef>(null);

  const [expandedItems, setExpandedItems] = useState<string[]>(getExpandedItemIds());
  const permissionsGetter = usePermissionsGetter();
  const libraryIds = useSelector(selectors.libraryIds);

  const handleExpandedItemsChange = useCallback((event: SyntheticEvent, itemIds: string[]) => {
    localStorage.setItem('space/menu/expanded', JSON.stringify(itemIds));
  }, []);

  const hasItemAdd = useCallback<NonNullable<SpaceTreeViewProps['hasItemAdd']>>(
    (itemId) => {
      return permissionsGetter.get(itemId)?.hasAction('WRITE') || false;
    },
    [permissionsGetter],
  );

  const hasItemMenu = useCallback<NonNullable<SpaceTreeViewProps['hasItemMenu']>>(() => {
    return true;
  }, []);

  const canItemDrag = useCallback<NonNullable<SpaceTreeViewProps['canItemDrag']>>(
    (event, context) => {
      const targetItem = parseItemId(context.itemId);
      return !(
        !context.rootItemId ||
        context.nodeType === 'space' ||
        libraryIds.includes(targetItem.entityId) ||
        context.editing ||
        !permissionsGetter.get(context.itemId)?.hasAction('WRITE')
      );
    },
    [permissionsGetter],
  );

  const canMoveItemToNewPosition = useCallback(
    (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
      const targetItem = parseItemId(params.itemId);
      const oldPosition = parseItemId(params.oldPosition.parentId);
      const newPosition = parseItemId(params.newPosition.parentId);
      if (targetItem.entityId === 'space' || libraryIds.includes(targetItem.entityId)) {
        return false;
      }
      if (newPosition && newPosition.entityId === 'space' && params.newPosition.index === 0) {
        return false;
      }
      if (oldPosition && oldPosition.entityId === 'space' && newPosition && libraryIds.includes(newPosition.entityId)) {
        return false;
      }
      if (!newPosition) {
        return false;
      }
      if (!permissionsGetter.get(newPosition.spaceId)?.hasAction('WRITE')) {
        return false;
      }
      return true;
    },
    [libraryIds, permissionsGetter],
  );

  const handleItemPositionChange = useCallback(
    (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
      const { entityId } = parseItemId(params.itemId);
      const { parentId: newParentKey, index: newIndex } = params.newPosition;
      if (!newParentKey) {
        return;
      }
      const { spaceId: newSpaceId, entityId: newParentId } = parseItemId(newParentKey.replace('space', 'root'));
      dispatcher.spaceResource.updatePosition({
        entityId,
        newPosition: {
          spaceId: newSpaceId,
          parentId: newParentId,
          index: newIndex,
        },
        treeName: 'structure',
      });
    },
    [],
  );

  const handleItemAdd = useCallback<NonNullable<SpaceTreeViewProps['onAddClick']>>(
    async (event, itemId) => {
      const { spaceId, entityId } = parseItemId(itemId);
      const parentId = ['space', ...libraryIds].includes(entityId) ? 'root' : entityId;
      const newId = `new-item-${uuidV4()}`;
      const newItemId = `${spaceId}::${newId}`;
      const api = spaceTreeViewRef.current?.getApi();
      api?.setItemExpansion(event, itemId, true);
      dispatcher.spaceResource.createFolder({ spaceId, parentId, position: 'last', markerId: newId });
      await delay(50);
      api?.focusItem(event, newItemId);
      api?.getItemDOMElement(newItemId)?.scrollIntoView({ behavior: 'smooth' });
      await delay(10);
      spaceTreeViewRef.current?.getInteractionsBridge()?.getTreeItemInteractions(newItemId)?.toggleItemEditing();
    },
    [libraryIds],
  );

  return {
    spaceTreeViewRef,
    expandedItems,
    hasItemAdd,
    hasItemMenu,
    canItemDrag,
    canMoveItemToNewPosition,
    handleExpandedItemsChange,
    handleItemPositionChange,
    handleItemAdd,
  };
};

export default useTree;
