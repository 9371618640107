import { type FC, memo, useCallback } from 'react';

import List from 'components/List';
import type { SpaceEntityKey } from 'app/entities';

import { Box, Typography } from '@mui/joy';
import useIsFolderLoading from '../model/useIsFolderLoading';
import useFolderHasNext from '../model/useFolderHasNext';
import useFolderChildren from '../model/useFolderChildren';
import useFolderNextPage from '../model/useFolderNextPage';
import ItemRender from '../elements/Item';

interface EntitiesProps {
  spaceId?: number;
  folderId?: string;
  canSelect?: boolean;
  canRemove?: boolean;
}

const Entities: FC<EntitiesProps> = (props) => {
  const { spaceId = 0, folderId = 'root', canSelect, canRemove } = props;

  const isLoading = useIsFolderLoading(spaceId, folderId);
  const hasNext = useFolderHasNext(spaceId, folderId);
  const list = useFolderChildren(spaceId, folderId);
  const { handler: nextPageHandler } = useFolderNextPage(spaceId, folderId);

  const getKey = useCallback((item: SpaceEntityKey) => {
    if (!item || typeof item !== 'object') {
      return 'unknown';
    }
    return `${item.__typename}-${item.id}`;
  }, []);

  if (list.length === 0) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: '90vh' }}>
        <Typography fontSize={22}>This folder is currently empty</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box height={2} />
      <List
        items={list}
        getKey={getKey}
        columnsScheme="320:1;528:2;672:3;960:4"
        loading={isLoading}
        hasNextPage={!isLoading && hasNext}
        onEndReached={nextPageHandler}
        slots={{
          item: ItemRender,
        }}
        slotProps={{
          item: {
            canSelect: canSelect ?? false,
            canRemove: canRemove ?? false,
          },
        }}
      />
      <Box height={0} mb={12} />
    </>
  );
};

export default memo(Entities);
