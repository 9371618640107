import { type SagaReturnType, takeEvery, put, cancel } from 'redux-saga/effects';

import { select, call } from 'store/utils/saga/effects';

import * as api from 'services/api';

import Alert from 'components/Alert';
import dialog from 'components/Dialog';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const config = {
  action: actions.highlightDelete.type,
  method: takeEvery,
};

const confirm = (): Promise<boolean> =>
  new Promise((resolve) => {
    dialog.show(
      {
        title: 'Confirmation',
        content: 'Are you sure want to delete\nthis highlight?',
      },
      [
        {
          label: 'Yes',
          color: 'danger',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(true);
          },
        },
        {
          label: 'Cancel',
          variant: 'plain',
          color: 'neutral',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            resolve(false);
          },
        },
      ],
    );
  });

export function* func(action: SagaReturnType<typeof actions.highlightDelete>) {
  const { id, resourceId } = action.payload;

  const isConfirmed = yield* call(() => confirm());

  if (!id || !resourceId || !isConfirmed) {
    yield put(actions.highlightDeleteDone(id, resourceId));
    yield cancel();
    return;
  }

  const material = yield* select(selectors.dataById(resourceId));
  if (!material) {
    yield put(actions.highlightDeleteDone(id, resourceId));
    yield cancel();
    return;
  }
  const { remarks = [] } = material;

  const oldRemarks = [...remarks];
  const newRemarks = remarks.filter((remark) => remark.type === 'note' || (remark.type === 'highlight' && remark.id !== id));

  yield put(actions.setItem([{ ...material, remarks: newRemarks }]));

  const { data, error } = yield* call(() => api.resource.content.highlight.delete(id));
  if (error || !data) {
    yield put(actions.setItem([{ ...material, remarks: oldRemarks }]));
    yield put(actions.highlightDeleteDone(id, resourceId));
    Alert.error(error?.message);
    yield cancel();
    return;
  }

  yield put(actions.highlightDeleteDone(id, resourceId));

  yield put(actions.loadById({ id: resourceId }));
}
