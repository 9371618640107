import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Provider } from 'react-redux';
import jwtToken from 'jwt-decode';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { enableScreens } from 'react-native-screens';
import { init as amplitudeInit, setUserId, getDeviceId, getSessionId } from '@amplitude/analytics-browser';
import { init as sessionReplayInit } from '@amplitude/session-replay-browser';

import ThemeProvider from 'lib/theme/Provider';

import 'mac-scrollbar/dist/mac-scrollbar.css';

import { useCachedResources, useColorScheme } from 'hooks';
import credentials from 'services/api/credentials';

import { store } from 'store';
import * as appActions from 'store/nodes/app/actions';

import Storage, { useStorageSync } from 'lib/Storage';
import network from 'lib/network';

import Main from 'components/Main';
import WebStyles from 'components/WebStyles';
import * as Modal from 'components/Modal';
import Modal2 from 'components/Modal2';
import ModalContainer from 'components/Modal3';
import { Dialogs } from 'components/Dialog';
import Modals from 'widgets/modals';
import { gtm } from 'utils';
import MuiXLicense from 'components/MuiXLicense';

import CopilotUnloadCacheClearer from 'widgets/Copilot/elements/UnloadCacheClearer';

enableScreens();

async function initAmplitude() {
  await amplitudeInit('1884fa43a667aad587fd9c01dbbf2ad3', {
    autocapture: true,
    minIdLength: 1,
  }).promise;

  const sessionId = getSessionId();
  const deviceId = getDeviceId();

  sessionReplayInit('1884fa43a667aad587fd9c01dbbf2ad3', {
    deviceId,
    sessionId,
    sampleRate: 0.5,
  });
}

initAmplitude();

network.on('REQUEST_INIT', (context) => {
  context.headers.Authorization = credentials.getAuthorizationHeader();
});

let isWebOAuth = false;
if (Platform.OS === 'web' && typeof window !== 'undefined' && typeof URLSearchParams !== 'undefined') {
  const [, query = ''] = (window.location.href || '').split(/\?/);
  const oauthParams = new URLSearchParams(query.replace(/#.*$/, '')?.trim());
  const access = oauthParams.get('access');
  const refresh = oauthParams.get('refresh');
  if (access && refresh) {
    isWebOAuth = true;
    const decodedToken: {
      session_id: number;
      login_type: 'login' | 'register';
    } = jwtToken(access);
    gtm.dataLayer.push({
      event: decodedToken?.login_type,
      method: 'linkedin',
      userid: decodedToken?.session_id.toString(),
    });
    setUserId(decodedToken.session_id.toString());
    Storage.setMulti({ '@access_token': access, '@refresh_token': refresh }, () => {
      const initRoute = localStorage.getItem('init-route') || '/ask';
      localStorage.removeItem('init-route');
      window.location.href = initRoute;
    });
  }
}

const App = () => {
  const isLoadingComplete = useCachedResources();
  const isStorageSynchronized = useStorageSync();

  const [isAppReady, setAppReady] = useState(false);
  const [isResourcesReady, setResourcesReady] = useState(Platform.OS === 'web');
  const [isNavigationReady, setNavigationReady] = useState(true);

  const theme = useColorScheme();

  useEffect(() => {
    if (isWebOAuth) {
      return;
    }
    store.subscribe(() => {
      if (store.getState().app.ready === true) {
        setAppReady(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isWebOAuth) {
      return;
    }
    if (isResourcesReady && isNavigationReady && isStorageSynchronized) {
      store.dispatch(appActions.ready());
    }
  }, [isResourcesReady, isNavigationReady, isStorageSynchronized]);

  useEffect(() => {
    if (isWebOAuth) {
      return;
    }
    if (isLoadingComplete) {
      console.timeLog('Time', 'Resource ready');
      setResourcesReady(true);
    }
  }, [isLoadingComplete]);

  const handleNavigationReady = useCallback(() => {
    console.timeLog('Time', 'Navigation ready');
    setNavigationReady(true);
  }, []);

  if (isWebOAuth || !isStorageSynchronized) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId="471525978303-vnv0eusbuq887ugfm81ah6jok3fa6a4i.apps.googleusercontent.com">
      <ThemeProvider>
        <WebStyles />
        <SafeAreaProvider>
          <Provider store={store}>
            <MuiXLicense />
            <Main onNavigationReady={handleNavigationReady} isAppReady={isAppReady && isNavigationReady} />
            {/* <GlobalScrollbar skin={theme} /> */}
            <Modals />
            {/* v2 */}
            <Modal2.Init />
            {/* v3 */}
            <ModalContainer />
            <Dialogs />
            <CopilotUnloadCacheClearer />
          </Provider>
        </SafeAreaProvider>
        {/* v1 */}
        <Modal.Init />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
