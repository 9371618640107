import { type FC, memo } from 'react';

import FolderLayout from './layouts/Folder';

interface SpaceProps {}

const Space: FC<SpaceProps> = (props) => {
  return <FolderLayout />;
};

export default memo(Space);
