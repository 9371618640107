import { type SagaReturnType, put, takeEvery, delay } from 'redux-saga/effects';

import network from 'lib/network';
import { requestCursorModifier } from 'utils';

import { call, select } from 'store/utils/saga/effects';

import { actions } from '../slices/resource';
import * as selectors from '../selectors';

import sourceEntityToSpaceEntity, { type SourceEntityType } from '../../model/sourceEntityToSpaceEntity';

export const config = {
  action: actions.loadPage.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof actions.loadPage>) {
  const { spaceId, folderIds, mode, treeName } = action.payload;
  const libraryIds = yield* select(selectors.libraryIds);

  for (const item of folderIds) {
    const parentId = item || 'root';

    let cursor: string | null = null;
    if (mode === 'next') {
      const hasNext = yield* select(selectors.folderHasNext(spaceId, parentId));
      if (!hasNext) {
        continue;
      }
      cursor = yield* select(selectors.folderNextCursor(spaceId, parentId));
      if (!cursor) {
        continue;
      }
    }

    const { data, errors } = yield* call(() =>
      network
        .request<SourceEntityType[]>(`/stack-2/user/team-space/${spaceId}/${parentId}/folder`)
        .query({ pageSize: 30, ...(cursor ? { cursor } : undefined) })
        .get(requestCursorModifier()),
    );

    if (errors && errors.length > 0 && errors[0].type === 'teamSpacePermissionError') {
      yield put(actions.loadPageError({ spaceId, parentId, error: { type: 'AccessError', data: null, id: parentId } }));
      continue;
    }

    if (!data) {
      yield put(actions.loadPageError({ spaceId, parentId, error: { type: 'UnknownError', data: null, id: parentId } }));
      continue;
    }

    const items = sourceEntityToSpaceEntity(libraryIds, data.items);

    const nextCursor = data.nextCursor ?? null;
    const hasNext = data.hasNext ?? false;

    yield put(actions.setPage({ data: items, mode, treeName }));
    yield put(
      actions.setPagination({
        spaceId,
        folderId: parentId,
        cursor: nextCursor,
        hasNext,
      }),
    );
  }

  yield delay(10);
  yield put(actions.loadPageDone({ spaceId, folderIds, treeName }));
}
