import { put, type SagaReturnType, takeEvery } from 'redux-saga/effects';
import { select } from 'store/utils/saga/effects';

import { guard } from 'utils';

import { actions as resourceActions } from '../slices/resource';

export const config = {
  action: resourceActions.removeByResourceId.type,
  method: takeEvery,
};

export function* func(action: SagaReturnType<typeof resourceActions.removeByResourceId>) {
  const { resourceIds } = action.payload;

  const resourceMap = yield* select((state) => state.spaceResource.data);

  for (const [entityId, entity] of Object.entries(resourceMap)) {
    if (!guard.isSpaceFolder(entity) && resourceIds.includes(entity.resourceId)) {
      yield put(resourceActions.remove({ entityIds: [entityId] }));
    }
  }
}
