import * as actions from 'store/nodes/plus/actions';
import { type SagaReturnType, put, takeLatest } from 'redux-saga/effects';

export const config = {
  action: actions.open.type,
  method: takeLatest,
};

export function* func(action: SagaReturnType<typeof actions.open>) {
  const { payload } = action;
  if (payload && 'parseFiles' in payload) {
    yield put(actions.parseFiles(payload.parseFiles));
  }
}
