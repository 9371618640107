import {
  type FC,
  type SyntheticEvent,
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ChangeEventHandler,
  type FocusEventHandler,
  useCallback,
  useRef,
  memo,
  useMemo,
} from 'react';
import { isEqual } from 'lodash';
import {
  TreeItem2Label,
  TreeItem2LabelInput,
  type UseTreeItem2LabelInputSlotOwnProps,
  type UseTreeItem2LabelSlotOwnProps,
} from '@mui/x-tree-view-pro';
import { Box, IconButton, Tooltip } from '@mui/joy';
import { type SxProps } from '@mui/system';

import Icon from 'ui/Icon';

import FolderIcon from './FolderIcon';

export interface LabelProps extends UseTreeItem2LabelSlotOwnProps {
  icon?: string | null;
  childrenCount?: number;
  isRoot: boolean;
  isHovered?: boolean;
  isPrivate?: boolean;
  editable: boolean;
  editing: boolean;
  toggleItemEditing: () => void;
  sx?: SxProps;
  hasAdd?: boolean;
  hasMenu?: boolean;
  onAddClick?: MouseEventHandler<HTMLButtonElement>;
  onMenuClick?: MouseEventHandler<HTMLButtonElement>;
}

const LabelComponent: FC<LabelProps> = (props) => {
  const {
    icon,
    childrenCount,
    isRoot,
    isHovered,
    isPrivate,
    editing,
    editable,
    children,
    toggleItemEditing,
    sx,
    onAddClick,
    onMenuClick,
    hasAdd,
    hasMenu,
    ...rest
  } = props;

  const showActions = useMemo(() => {
    if (!hasAdd && !hasMenu) {
      return false;
    }
    return isHovered;
  }, [isHovered, hasAdd, hasMenu]);

  return (
    <TreeItem2Label
      {...rest}
      editable={editable}
      sx={{
        minHeight: 28,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        fontSize: isRoot ? 16 : 13,
        fontWeight: 400,
        color: 'var(--joy-palette-text-secondary)',
        ...sx,
      }}
    >
      <FolderIcon url={icon} sx={{ pointerEvents: 'none' }} />
      <Box
        position="relative"
        flex={1}
        mt={isRoot ? -0.25 : 0}
        overflow="hidden"
        sx={{
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Box>
      {!showActions && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={0.5}
          mr={1}
          fontSize={13}
          sx={{
            pointerEvents: 'none',
          }}
        >
          {isPrivate && <Icon name="lock" size="xs" fw sx={{ opacity: 0.25 }} />}
          {childrenCount !== null && <div>{childrenCount}</div>}
        </Box>
      )}
      {showActions && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={0.25}
          mr={0.25}
          fontSize={13}
          sx={{
            '--IconButton-size': '1.5rem',
          }}
        >
          {hasMenu && (
            <Tooltip arrow disableInteractive variant="outlined" size="sm" title="Delete, settings, share, and more...">
              <IconButton sx={{ p: 0, fontSize: 12 }} onClick={onMenuClick}>
                <Icon name="ellipsis" size="1x" fw weight="regular" color="icon" />
              </IconButton>
            </Tooltip>
          )}
          {hasAdd && (
            <Tooltip arrow disableInteractive variant="outlined" size="sm" title="New collection">
              <IconButton sx={{ p: 0, fontSize: 12 }} onClick={onAddClick}>
                <Icon name="plus" size="1x" fw weight="regular" color="icon" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </TreeItem2Label>
  );
};

export interface LabelInputProps extends UseTreeItem2LabelInputSlotOwnProps {
  icon?: string;
  childrenCount?: number;
  isRoot: boolean;
  isPrivate?: boolean;
  handleCancelItemLabelEditing: (event: SyntheticEvent) => void;
  handleSaveItemLabel: (event: SyntheticEvent, label: string) => void;
  value: string;
  sx?: SxProps;
}

const LabelInputComponent: FC<Omit<LabelInputProps, 'ref'>> = (props) => {
  const { isRoot, handleCancelItemLabelEditing, handleSaveItemLabel, onChange, value, sx, ...rest } = props;

  const canBeCanceled = useRef<boolean>(true);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      canBeCanceled.current = false;
      if (event.key === 'Escape') {
        handleCancelItemLabelEditing(event);
      } else if (event.key === 'Enter') {
        handleSaveItemLabel(event, value);
      }
    },
    [handleCancelItemLabelEditing, handleSaveItemLabel, value],
  );

  const handleClick = useCallback<MouseEventHandler<HTMLInputElement>>((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange?.(event);
    },
    [onChange],
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      if (canBeCanceled.current) {
        handleCancelItemLabelEditing(event);
      }
    },
    [handleCancelItemLabelEditing],
  );

  return (
    <>
      <TreeItem2LabelInput
        {...rest}
        onChange={handleChange}
        value={value}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        onBlur={handleBlur}
        sx={{
          pointerEvents: 'all',
          minHeight: 28,
          fontSize: isRoot ? 16 : 13,
          borderRadius: 16,
          color: 'var(--joy-palette-text-primary)',
          px: 0.5,
          ...sx,
        }}
      />
    </>
  );
};

export const Label = memo(LabelComponent, (prevProps, nextProps) => isEqual(prevProps, nextProps));
export const LabelInput = memo(LabelInputComponent, (prevProps, nextProps) => isEqual(prevProps, nextProps));
