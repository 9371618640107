import { Box, Typography } from '@mui/joy';
import { type PropsWithChildren, useCallback, memo, forwardRef, useImperativeHandle } from 'react';
import { type Accept, type DropzoneRef, useDropzone } from 'react-dropzone';
import { useSelector } from 'store/utils/redux/hooks';
import * as valueStore from 'store/nodes/value';

type DropzoneProps = {
  accept?: Accept;
  onAcceptedFiles?: (files: File[]) => void;
  zIndex?: number;
  isShow?: boolean;
};

const Dropzone = forwardRef<DropzoneRef, PropsWithChildren<DropzoneProps>>((props, ref) => {
  const { accept, onAcceptedFiles, zIndex, isShow } = props;
  const contentWidthPanel = useSelector(valueStore.selectors.get<number>('contentWidthPanel'));

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      onAcceptedFiles?.(acceptedFiles);
    },
    [onAcceptedFiles],
  );

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    accept,
    onDrop,
    noClick: true,
  });

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open],
  );

  return (
    <Box
      display={isShow ? 'flex' : 'none'}
      position="fixed"
      sx={{ height: 'calc(100vh - 80px)', width: `${contentWidthPanel}px`, zIndex }}
      flexDirection="column"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive && (
        <Box
          sx={{
            borderRadius: '0.625em',
            backdropFilter: 'blur(8px)',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            width: `${contentWidthPanel}px`,
            height: '100%',
            border: `1px solid var(--joy-palette-primary-500)`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomWidth: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.body,
              borderRadius: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 10,
            }}
          >
            <Typography mx={7} my={4} fontSize={22}>
              Drag-and-drop files will be uploaded to the library
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default memo(Dropzone);
