import { type MouseEvent, type SyntheticEvent, useCallback, useMemo, useState } from 'react';

import useFolderMeta from '../../../model/useFolderMeta';
import { parseItemId } from '../../../model/itemIdParser';

import ContextMenuComponent, { type ContextMenuActionsType } from '../ui/ContextMenu';

export type MenuItemType = 'share' | 'settings' | 'permissions' | 'rename' | 'delete' | 'private';

export type UseContentMenuParamsActionContext =
  | { name: Omit<ContextMenuActionsType, 'TogglePrivate'>; itemId: string; spaceId: number; entityId: string }
  | { name: 'TogglePrivate'; itemId: string; spaceId: number; entityId: string; newValue: boolean };

export type UseContentMenuParamsOpenContext = {
  itemId: string;
  spaceId: number;
  entityId: string;
  addMenuItem: (value: MenuItemType) => void;
};

export interface UseContentMenuParams {
  onAction?: (event: SyntheticEvent, context: UseContentMenuParamsActionContext) => void;
  onOpenRequest?: (event: SyntheticEvent, context: UseContentMenuParamsOpenContext) => boolean;
  onOpen?: (event: SyntheticEvent, context: Omit<UseContentMenuParamsOpenContext, 'addMenuItem'>) => void;
}

const useContextMenu = (params?: UseContentMenuParams) => {
  const [contextMenuState, setContextMenuState] = useState<{
    open: boolean;
    menuItems: MenuItemType[];
    itemId: string;
    coords: { x: number; y: number };
  }>({
    open: false,
    menuItems: [],
    itemId: '',
    coords: { x: 0, y: 0 },
  });

  const { isPrivate } = useFolderMeta(contextMenuState.itemId);

  const handleContextMenu = useCallback(
    (event: MouseEvent) => {
      const itemId = event.currentTarget?.closest('[role="treeitem"]')?.getAttribute('itemId');
      if (!itemId) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      const { spaceId, entityId } = parseItemId(itemId);
      const menuItems: MenuItemType[] = [];
      const allowOpening = params?.onOpenRequest
        ? params.onOpenRequest(event, {
            itemId,
            spaceId,
            entityId,
            addMenuItem: (value) => {
              menuItems.push(value);
            },
          })
        : true;
      if (!allowOpening) {
        return;
      }
      setContextMenuState({
        open: true,
        menuItems,
        itemId,
        coords: { x: event.clientX, y: event.clientY },
      });
      params?.onOpen?.(event, { itemId, spaceId, entityId });
    },
    [params?.onOpen, params?.onOpenRequest],
  );

  const handleOpenChange = useCallback((open: boolean) => {
    setContextMenuState((prev) => ({ ...prev, open }));
  }, []);

  const handleAction = useCallback(
    (event: SyntheticEvent, name: ContextMenuActionsType) => {
      const { spaceId, entityId } = parseItemId(contextMenuState.itemId);
      if (name !== 'TogglePrivate') {
        params?.onAction?.(event, { name, itemId: contextMenuState.itemId, spaceId, entityId });
      }
      if (name === 'TogglePrivate') {
        params?.onAction?.(event, { name, itemId: contextMenuState.itemId, spaceId, entityId, newValue: !isPrivate });
      }
      setContextMenuState((prev) => ({ ...prev, menuItems: [], open: false }));
    },
    [contextMenuState.itemId, isPrivate],
  );

  return useMemo(
    () => ({
      handleContextMenu,
      ContextMenu: () => (
        <ContextMenuComponent
          open={contextMenuState.open}
          coords={contextMenuState.coords}
          onOpenChange={handleOpenChange}
          onAction={handleAction}
          isPrivate={isPrivate}
          hasShare={contextMenuState.menuItems.includes('share')}
          hasSettings={contextMenuState.menuItems.includes('settings')}
          hasPermissions={contextMenuState.menuItems.includes('permissions')}
          hasRename={contextMenuState.menuItems.includes('rename')}
          hasDelete={contextMenuState.menuItems.includes('delete')}
          hasPrivate={contextMenuState.menuItems.includes('private')}
        />
      ),
    }),
    [handleContextMenu, contextMenuState, isPrivate, handleAction],
  );
};

export default useContextMenu;
