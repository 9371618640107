import { useMemo } from 'react';

import { useSelector } from 'store/utils/redux/hooks';
import useSpaceById from 'screens/Space/model/useSpaceById';

const useMyLibrary = () => {
  const spaceId = useSelector((state) => Math.min(...Object.keys(state.spaceList.data || {}).map(Number)));
  const { space } = useSpaceById(spaceId);
  const entityId = space?.libraryId || 'root';

  return useMemo(
    () => ({
      spaceId,
      entityId,
    }),
    [spaceId, entityId],
  );
};

export default useMyLibrary;
