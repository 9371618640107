import { useMemo } from 'react';
import Copilot from 'widgets/Copilot';

const usePanelsRender = (spaceId?: number, folderId?: string) => {
  return useMemo(() => {
    return [
      ...(spaceId && folderId
        ? [
            {
              label: 'AI Copilot',
              value: 'copilot',
              component: <Copilot {...(folderId === 'root' ? { variant: 'Space', spaceId } : { variant: 'SpaceFolder', spaceId, folderId })} />,
            },
          ]
        : []),
    ];
  }, [spaceId, folderId]);
};

export default usePanelsRender;
