import { type FC, memo, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/joy';
import { isEqual } from 'lodash';

import AdaptivePage from 'components/AdaptivePage';
import PlusButton from 'components/PlusButton';
import AddNoteButton from 'components/AddNoteButton';
import SearchInput from 'components/SearchInput';

import useGlobalDragCounter from 'hooks/useGlobalDragCounter';

import { CopilotModelSelect, CopilotShowToggle, CopilotUtilityBar } from 'widgets/Copilot';
import PanelsBar from 'widgets/PanelsBar';

import { acceptContentTypes } from 'utils/extensionByContentType';
import { useDispatcher, useSelector } from 'store/utils/redux/hooks';

import useLoadError from '../model/useLoadError';
import { useFolderRoute } from '../model/folderRoute';
import { SelectionProvider } from '../model/SelectionContext';
import usePanelsRender from '../model/usePanelsRender';
import usePermissionsGetter from '../model/usePermissionsGetter';

import Entities from '../elements/Entities';
import SelectionBar from '../elements/SelectionBar';
import Dropzone from '../elements/DropZone';

interface FolderProps {}

const Folder: FC<FolderProps> = () => {
  const dispatcher = useDispatcher();

  const { spaceId, folderId, itemId } = useFolderRoute();
  const { hasError, message: errorMessage } = useLoadError(spaceId, folderId);

  const spaceName = useSelector((state) => (!spaceId ? '' : state.spaceList?.data?.[spaceId]?.title));
  const panelsRender = usePanelsRender(spaceId, folderId);

  const permissionsGetter = usePermissionsGetter();
  const isDragActiveGlobal = useGlobalDragCounter();

  const hadPermissionWrite = useMemo(() => {
    if (!itemId) {
      return false;
    }
    return permissionsGetter.get(itemId)?.hasAction('WRITE');
  }, [permissionsGetter, itemId]);

  const handleAcceptedFiles = useCallback(
    async (files?: File[]) => {
      if (!files) {
        return;
      }
      dispatcher.plus.open({
        hasChangeFolder: false,
        parseFiles: {
          files: Array.from(files),
          spaceId,
          folderId,
          privacy: true,
        },
      });
    },
    [spaceId, folderId],
  );

  return (
    <SelectionProvider>
      <AdaptivePage
        desktopHeaderLeftPanel={false}
        desktopHeaderMiddlePanel={
          <Box display="flex" flexDirection="row" alignItems="center" gap={2} alignSelf="flex-end" flex={1} width="100%">
            <SearchInput size="medium" placeholder={hasError ? 'Search' : `Search by space "${spaceName}"`} disabled={hasError} />
            <Box flex={1} />
            <CopilotShowToggle mr={-1} isShowForce={hasError ? false : undefined} disabled={hasError} />
          </Box>
        }
        desktopHeaderRightPanel={
          <CopilotUtilityBar
            isShowForce={hasError ? false : undefined}
            pl={1}
            left={
              <>
                <CopilotUtilityBar.Item canHidden>
                  <CopilotModelSelect ml={2} />
                </CopilotUtilityBar.Item>
              </>
            }
            right={
              <>
                <AddNoteButton />
                <PlusButton />
              </>
            }
          />
        }
        bodyMaxWidth={1280}
        paddingHorizontalScheme={{ 320: 16 }}
        scrollToStart
      >
        <PanelsBar
          isShowForce={hasError ? false : undefined}
          contentMeta={{
            label: 'Content',
            value: 'content',
          }}
          content={
            <>
              {hasError && (
                <>
                  <Typography level="h3">{errorMessage}</Typography>
                </>
              )}
              {!hasError && (
                <>
                  <Entities spaceId={spaceId} folderId={folderId} canSelect={hadPermissionWrite} canRemove={hadPermissionWrite} />
                  {hadPermissionWrite && <SelectionBar spaceId={spaceId} folderId={folderId} detectPositionSelector="div.entities" />}
                  {hadPermissionWrite && (
                    <Dropzone accept={acceptContentTypes} onAcceptedFiles={handleAcceptedFiles} zIndex={100} isShow={isDragActiveGlobal} />
                  )}
                </>
              )}
            </>
          }
          contentProps={{
            className: 'entities',
          }}
          panels={panelsRender}
          sx={{
            '@media (max-width: 767px)': {
              mx: -2,
            },
          }}
        />
      </AdaptivePage>
    </SelectionProvider>
  );
};

export default memo(Folder, (prevProps, nextProps) => isEqual(prevProps, nextProps));
