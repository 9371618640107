import { type FC, useCallback, useState, memo } from 'react';
import moment from 'moment-timezone';
import { Box, IconButton, Typography, useColorScheme } from '@mui/joy';

import Icon from 'ui/Icon';

export type JobProps = {
  id: number;
  company: string;
  position: string;
  location?: string | null;
  startDate: string;
  endDate?: string | null;
  description?: string | null;
  editable?: boolean;
  onEdit?: (id: number) => void;
};

const Job: FC<JobProps> = (props) => {
  const { id, company, position, location = '', startDate, endDate, description = '', editable, onEdit } = props;
  const { mode } = useColorScheme();

  const [isExpand, setExpand] = useState(false);

  const handleToggleExpand = useCallback(() => {
    setExpand((prev) => !prev);
  }, []);

  const handleEdit = useCallback(() => {
    onEdit?.(id);
  }, [id, onEdit]);

  const startDateFinal = moment(startDate).format('MMM YYYY');
  const endDateFinal = endDate ? moment(endDate).format('MMM YYYY') : 'Now';

  return (
    <Box
      sx={{
        borderRadius: '15px',
        px: 2,
        py: 1.5,
        mt: 2,
        background:
          mode === 'light'
            ? `color-mix(in srgb, var(--joy-palette-background-popup) 50%, var(--joy-palette-background-level1) 50%)`
            : 'var(--joy-palette-background-level1)',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <Typography fontSize={22} fontWeight={500}>
            {company}
          </Typography>
          {location && (
            <Typography fontSize={11} fontWeight={500} sx={{ mt: 0.5, opacity: 0.5 }}>
              {location}
            </Typography>
          )}
        </Box>
        {editable && (
          <IconButton onClick={handleEdit} sx={{ mt: -2.5 }}>
            <Icon name="ellipsis" size="lg" />
          </IconButton>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', mt: 3 }}>
        <Typography fontSize={20} fontWeight={500}>
          {position}
        </Typography>
        <Box sx={{ flex: 1 }} />
        <IconButton variant="outlined" onClick={handleToggleExpand}>
          {!isExpand ? <Icon name="chevron-down" size="lg" /> : <Icon name="chevron-up" size="lg" />}
        </IconButton>
      </Box>
      {isExpand && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
            borderTop: '1px solid',
            borderTopColor: 'divider',
            pt: 2,
          }}
        >
          <Typography fontSize={15} fontWeight={500}>
            Start / end of work
          </Typography>
          <Typography fontSize={13} sx={{ mt: 0.25, opacity: 0.5 }}>
            {`${startDateFinal} - ${endDateFinal}`}
          </Typography>
          {description && (
            <>
              <Typography fontSize={15} fontWeight={500} sx={{ mt: 2 }}>
                Job description
              </Typography>
              <Typography fontSize={13} sx={{ mt: 0.25, opacity: 0.5 }}>
                {description}
              </Typography>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(Job);
