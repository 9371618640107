import { Box, Button, DialogActions, FormControl, FormLabel, Input, Stack, formControlClasses, Textarea, Typography } from '@mui/joy';
import { memo, useCallback } from 'react';
import type { UserType } from 'app/entities';
import { dispatcher } from 'store/utils/redux/hooks';

import dialog from 'components/Dialog';
import { PhotoInput } from 'components/Form';
import Icon from 'ui/Icon';

type ProfileLayoutProps = {
  formData: UserType | null;
  handleChange: (event: { target: { name: string; value: string } }) => void;
};

const ProfileLayout = (props: ProfileLayoutProps) => {
  const { formData, handleChange } = props;

  const handleSignOut = useCallback(() => {
    dispatcher.auth.logOut();
    dispatcher.modal.close('Profile');
  }, []);

  const handleDeleteAccount = useCallback(() => {
    dialog.show(
      {
        title: 'Delete account',
        content:
          'Please note: you will not be able to restore your account, all the data is deleted permanently along with your subscription.\n' +
          'Do you want to proceed and delete your account, data and subscription?',
      },
      [
        {
          label: 'Delete',
          color: 'danger',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
            dispatcher.profile.deleteAccount();
            dispatcher.modal.close('Profile');
          },
        },
        {
          label: 'Cancel',
          variant: 'plain',
          color: 'neutral',
          handle: (dialogId: string) => {
            dialog.hide(dialogId);
          },
        },
      ],
    );
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="calc(100% - 11rem - 3.5rem)"
      sx={{
        '@media (max-width: 767px)': {
          width: '100%',
        },
      }}
    >
      <Box
        display="flex"
        alignSelf="center"
        mb={1.5}
        position="relative"
        sx={{
          '@media (max-width: 767px)': {
            mt: '1rem',
          },
        }}
      >
        <PhotoInput
          value={formData?.photo as string | undefined}
          alt={`${formData?.name} ${formData?.surname}`.trim()}
          onChange={(value) => {
            const event = {
              target: {
                name: 'photo',
                value,
              },
            };
            handleChange(event);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            width: 32,
            height: 32,
            borderRadius: 16,
            justifyContent: 'center',
            alignItems: 'center',
            right: 0,
            bottom: 0,
            backgroundColor: (theme) => theme.palette.neutral.softBg,
          }}
        >
          <Icon name="edit" />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        sx={{
          '@media (max-width: 767px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          marginTop="calc(1 * var(--joy-spacing))"
          sx={{
            [`&  .${formControlClasses.root}`]: {
              width: 'calc(50% - var(--joy-spacing))',
              '@media (max-width: 460px)': {
                width: '100%',
              },
            },
          }}
        >
          <FormControl disabled>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>Email</FormLabel>
            <Input variant="plain" value={formData?.email || ''} name="email" onChange={handleChange} fullWidth />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>User Name</FormLabel>
            <Input variant="plain" value={formData?.login || ''} name="login" onChange={handleChange} fullWidth />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>First Name</FormLabel>
            <Input variant="plain" value={formData?.name || ''} name="name" onChange={handleChange} fullWidth />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>Last Name</FormLabel>
            <Input variant="plain" value={formData?.surname || ''} name="surname" onChange={handleChange} fullWidth />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>Profession</FormLabel>
            <Input variant="plain" value={formData?.profession || ''} name="profession" onChange={handleChange} fullWidth />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontSize: 13, fontWeight: 400, m: 0 }}>Website</FormLabel>
            <Input variant="plain" value={formData?.websiteLink || ''} name="websiteLink" onChange={handleChange} fullWidth />
          </FormControl>
        </Stack>
      </Box>
      <DialogActions sx={{ mt: 1.5, gap: 2, flexDirection: 'row' }}>
        <Button variant="soft" fullWidth onClick={handleSignOut} color="neutral" sx={{ fontWeight: 400, flex: 1 }}>
          Sign Out
        </Button>
        <Button
          variant="soft"
          fullWidth
          onClick={handleDeleteAccount}
          color="neutral"
          sx={{ color: 'var(--joy-palette-danger-solidBg)', fontWeight: 400, flex: 1 }}
        >
          Delete Account
        </Button>
      </DialogActions>
    </Box>
  );
};

export default memo(ProfileLayout);
