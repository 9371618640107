import { type ReactElement, type PropsWithChildren, type MouseEventHandler, useCallback } from 'react';
import * as ImagePicker from 'expo-image-picker';

import type { ImageType } from 'app/entities';

import { Box, IconButton } from '@mui/joy';
import Icon from 'ui/Icon';

type CustomImageInputProps = {
  value?: ImageType | null;
  render: (value?: ImageType | null) => ReactElement;
  onChange?: (event: { type: 'select' | 'delete'; value: ImageType | null }) => void;
};

const CustomImageInput = (props: PropsWithChildren<CustomImageInputProps>): ReactElement => {
  const { value, render, onChange } = props;

  const handlePick = useCallback(async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (result.canceled || !result?.assets[0].uri) {
      return;
    }

    const [asset] = result.assets;

    onChange?.({
      type: 'select',
      value: {
        id: 0,
        url: asset.uri,
        width: asset.width,
        height: asset.height,
        createdAt: '',
        updatedAt: '',
      },
    });
  }, [onChange]);

  const handleDelete = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onChange?.({
        type: 'delete',
        value: null,
      });
    },
    [onChange],
  );

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        cursor: 'pointer',
        '--variant-softBg': 'color-mix(in srgb, var(--joy-palette-neutral-softBg) 20%, transparent 80%)',
        '--variant-softHoverBg': 'color-mix(in srgb, var(--joy-palette-neutral-softHoverBg) 30%, transparent 70%)',
        '--variant-softActiveBg': 'color-mix(in srgb, var(--joy-palette-neutral-softActiveBg) 25%, transparent 75%)',
      }}
      onClick={handlePick}
    >
      {render(value)}
      {!!value && (
        <>
          <IconButton
            variant="soft"
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleDelete}
          >
            <Icon name="trash" color="var(--mui-palette-error-main)" />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default CustomImageInput;
