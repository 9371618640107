import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react';
import { isEqual } from 'lodash';

import { useEditorState } from 'hooks';

import NovelEditor, { type ContentCommandMethods, type NovelEditorProps } from 'widgets/Novel';
import useData from '../model/useData';
import useSave from '../model/useSave';
import useCreate from '../model/useCreate';

export type { ContentCommandMethods };

export type EditorProcessorProps = {
  id: number | 'create';
  createParams?: {
    spaceId?: number;
    folderId?: string;
  };
  autofocus?: boolean;
  onFocus?: NovelEditorProps['onFocus'];
  onBlur?: NovelEditorProps['onBlur'];
};

const EditorProcessor = forwardRef<ContentCommandMethods, EditorProcessorProps>((props, forwardedRef) => {
  const { id, createParams, onFocus, autofocus, onBlur } = props;

  const { data, initHtml } = useData(id);
  const editorRef = useRef<ContentCommandMethods | null>(null);

  const save = useSave();
  const create = useCreate(createParams);

  const { html, text, hasContent, onChange } = useEditorState(data && 'text' in data ? data?.text : '');

  useImperativeHandle(forwardedRef, () => ({
    insert: ((...args) => editorRef?.current?.insert(...args)) as ContentCommandMethods['insert'],
    focus: ((...args) => editorRef?.current?.focus(...args)) as ContentCommandMethods['focus'],
  }));

  useEffect(() => {
    if (autofocus && editorRef.current) {
      editorRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (typeof id === 'number') {
      save(id, html, text);
    }
    if (id === 'create' && hasContent) {
      create(html, text);
    }
  }, [id, html, text, save, hasContent]);

  return <NovelEditor ref={editorRef} onHtmlChanged={onChange} initHtml={initHtml || ''} onFocus={onFocus} onBlur={onBlur} />;
});

export default memo(EditorProcessor, (prevProps, nextProps) => isEqual(prevProps, nextProps));
