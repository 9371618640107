import { type FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import { useDispatcher } from 'store/utils/redux/hooks';

import type { SpaceFolderType } from 'app/entities';
import * as modalStore from 'widgets/modals/store';

import useFolderState from './model/useFolderState';
import FolderSettingsLayout from './layout/FolderSettingsLayout';

export type FolderSettingsParams = {
  folderId?: SpaceFolderType['id'];
};

const FolderSettings: FC = () => {
  const dispatcher = useDispatcher();
  const { isOpen, params } = useSelector(modalStore.selectors.state('SpaceFolderSettings', { folderId: undefined }));

  const { data, change, update } = useFolderState(params?.folderId);

  const handleClose = useCallback(() => {
    dispatcher.modal.close('SpaceFolderSettings');
  }, [dispatcher]);

  const handleFolderUpdate = useCallback(() => {
    if (update) {
      update();
    }
    handleClose();
  }, [update, handleClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open onClose={handleClose}>
      <ModalDialog
        variant="soft"
        color="neutral"
        size="lg"
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: 'min(390px, calc(100% - 32px))',
          maxWidth: 'min(670px, calc(100% - 32px))',
          borderRadius: '1.25rem',
          '--Card-padding': '2.5rem',
          '--FormLabel-color': 'var(--joy-palette-neutral-plainDisabledColor)',
          '--variant-solidDisabledBg': 'var(--joy-palette-background-level2)',
        }}
      >
        <ModalClose onClick={handleClose} size="sm" sx={{ borderRadius: '1rem', m: 2 }} />
        <FolderSettingsLayout
          header={
            <Box display="flex" flexDirection="column" gap={1} flex={1}>
              <Typography fontSize={34} fontWeight={400}>
                Edit Folder
              </Typography>
            </Box>
          }
          data={data}
          submitLabel="Save"
          onChange={change}
          onSubmit={handleFolderUpdate}
          onCancel={handleClose}
        />
      </ModalDialog>
    </Modal>
  );
};

export default memo(FolderSettings);
